import { useState } from "react";
import DatePicker from "react-datepicker";
import { BsCalendar3 } from "react-icons/bs";

import { useFetch } from "../../../hooks/useFetch";
import { convertGMTtoDateInUTC } from "../../../utils";
import ClanRanking from "../../ClanRanking";
import Loader from "../../Loader";
import PlayersRanking from "../../PlayersRanking";
const OPTIONS_RANKING = [
  {
    value: "all",
    label: "All Categories",
  },
  {
    value: "bronze",
    label: "Bronze",
  },
  {
    value: "silver",
    label: "Silver",
  },
  {
    value: "gold",
    label: "Gold",
  },
];

export const Ranking = () => {
  const {
    zombieRankingLeaderboard,
    setLeaderboardPeriod,
    zombieRankingLeaderboardIsLoadingByClan,
    zombieRankingLeaderboardByClan,
  } = useFetch();
  const [ranking, setRanking] = useState("all");
  const [isClan, setIsClan] = useState(false);

  const [dateStart, setDateStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0),
  );

  const [dateEnd, setDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
  );

  const handleLeaderboardPeriod = (period: {
    dateStart: Date;
    dateEnd: Date;
  }) => {
    const dayTmp = 24 * 3600 * 1000;

    const utcPeriod: {
      dateStart: Date;
      dateEnd: Date;
    } = {
      dateStart: convertGMTtoDateInUTC(period.dateStart.getTime()),
      dateEnd: convertGMTtoDateInUTC(period.dateEnd.getTime() + dayTmp - 1000),
    };

    setLeaderboardPeriod(utcPeriod);
  };

  return (
    <section className="mb-10 mt-10 flex w-full flex-col items-center justify-center md:w-[100%] md:min-w-[405px] ">
      <article className={`flex flex-col items-center gap-2`}>
        <h4 className="font-impact w-full text-center text-4xl font-normal uppercase text-white">
          {"PvP Leaderboard"}
        </h4>
      </article>
      <div>
        <div className="relative flex gap-8 rounded-lg p-4 ">
          <div
            className={`w-1/2 cursor-pointer rounded-md p-2 text-xl font-bold transition-all ${
              isClan ? "" : "bg-basket_blue-950"
            }`}
            onClick={() => setIsClan(false)}
          >
            Players
          </div>
          <div
            className={`w-1/2 cursor-pointer rounded-md p-2 text-xl font-bold transition-all ${
              !isClan ? "" : "bg-basket_blue-950 "
            }`}
            onClick={() => setIsClan(true)}
          >
            Clans
          </div>
        </div>
      </div>
      <section className="gold-border mt-10 w-[350px] items-center justify-center rounded-[15px] border-2 bg-basket_blue-950 p-2 text-left md:w-[700px] md:p-6 xl:w-[1200px]">
        <div className={`flex flex-col items-center gap-5 xl:flex-row`}>
          <aside className="md mb-5 flex w-full flex-col text-center lg:text-left">
            <span className="font-impact font-medium uppercase text-white md:text-[20px]">
              Period:
            </span>
            <div className="flex w-full flex-col items-center gap-2 md:flex-row">
              <BsCalendar3 className="hidden text-4xl text-white md:block" />
              <div className="font-impact flex w-full items-start font-medium uppercase text-white md:text-[20px] lg:gap-3">
                <DatePicker
                  tabIndex={99}
                  className={`mb-2 w-[70%] max-w-[100%] rounded-lg bg-[#3B3B3B] p-1.5 outline-none lg:w-full`}
                  selected={new Date(dateStart.toISOString())}
                  onChange={date => date && setDateStart(date)}
                />
                {" - "}
                <DatePicker
                  tabIndex={99}
                  className={`w-[70%] max-w-[100%] rounded-lg bg-[#3B3B3B] p-1.5 outline-none lg:w-full`}
                  selected={new Date(dateEnd.toISOString())}
                  onChange={date => date && setDateEnd(date)}
                />
              </div>
              <button
                className="gamebtn"
                onClick={() => {
                  handleLeaderboardPeriod({
                    dateStart: new Date(dateStart),
                    dateEnd: new Date(dateEnd),
                  });
                }}
              >
                Filter
              </button>
            </div>
          </aside>
          {/* <aside className="mb-5 flex flex-col">
            <span className="font-impact font-medium uppercase text-white md:text-[20px]">
              Filter By Category:
            </span>
            <select
              className={`rounded-lg bg-[#3B3B3B] p-1.5 outline-none`}
              onChange={e => setRanking(e.target.value)}
            >
              {OPTIONS_RANKING.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </aside> */}
        </div>
        {isClan ? (
          <ClanRanking ranking={zombieRankingLeaderboardByClan} />
        ) : (
          <PlayersRanking ranking={zombieRankingLeaderboard} />
        )}
      </section>
    </section>
  );
};
