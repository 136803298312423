var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"081c80d6b2a2e8cdc8f94fdb20255f04cc0071d4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const wallet = localStorage.getItem("sessionID") || "your wallet";

Sentry.init({
  dsn: "https://e919a290e7e109b552e9e83fc8d893f1@o4507081030107136.ingest.us.sentry.io/4507081057435648",
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.setContext("wallet", {
        wallet,
      });
    }
    return event;
  },
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.feedbackIntegration({
      nameLabel: "Wallet",
      colorScheme: "system",
      buttonLabel: "",
      namePlaceholder: wallet,
      isNameRequired: true,
      autoInject: true,
      messagePlaceholder:
        "Please, disable adblock extensions before submitting the feedback.",
    }),
  ],
});
